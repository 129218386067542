@keyframes blinker
  50%
    opacity 0

@keyframes rotateThatBiz
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)

@keyframes bounce
  0%
  80%
  100%
    transform scale(0)
  40%
    transform scale(1.0)

