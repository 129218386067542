ks-meyer-reset();
ks-box-sizing-reset();
ks-normalize();

html
  height -webkit-fill-available
  width 100vw
  position fixed

body
  -moz-osx-font-smoothing grayscale
  -webkit-font-smoothing antialiased
  font-family Averta
  font-size 18px
  font-weight 600
  letter-spacing normal
  overflow hidden
  min-height 100vh
  min-height -webkit-fill-available
  width 100vw

#app
  height 100%
  width 100vw

code
  font-family source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace

label
  font-size 14px
  text-transform uppercase
  font-weight normal

button
  -webkit-appearance none
  background-color unset
  border none
  padding 0

button
input
select
textarea
optgroup
  font-family Averta

:focus
  outline none

@font-face
  font-family 'Averta-Extrathin'
  src url('/font/averta/averta-extrathin.eot')
  src url('/font/averta/averta-extrathin.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-extrathin.woff2') format('woff2'),url('/font/averta/averta-extrathin.woff') format('woff'),url('/font/averta/averta-extrathin.ttf') format('truetype')

@font-face
  font-family 'Averta-ExtrathinItalic'
  src url('/font/averta/averta-extrathinitalic.eot')
  src url('/font/averta/averta-extrathinitalic.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-extrathinitalic.woff2') format('woff2'),url('/font/averta/averta-extrathinitalic.woff') format('woff'),url('/font/averta/averta-extrathinitalic.ttf') format('truetype')

@font-face
  font-family 'Averta-Thin'
  src url('/font/averta/averta-thin.eot')
  src url('/font/averta/averta-thin.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-thin.woff2') format('woff2'),url('/font/averta/averta-thin.woff') format('woff'),url('/font/averta/averta-thin.ttf') format('truetype')

@font-face
  font-family 'Averta-ThinItalic'
  src url('/font/averta/averta-thinitalic.eot')
  src url('/font/averta/averta-thinitalic.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-thinitalic.woff2') format('woff2'),url('/font/averta/averta-thinitalic.woff') format('woff'),url('/font/averta/averta-thinitalic.ttf') format('truetype')

@font-face
  font-family 'Averta-Light'
  src url('/font/averta/averta-light.eot')
  src url('/font/averta/averta-light.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-light.woff2') format('woff2'),url('/font/averta/averta-light.woff') format('woff'),url('/font/averta/averta-light.ttf') format('truetype')

@font-face
  font-family 'Averta-LightItalic'
  src url('/font/averta/averta-lightitalic.eot')
  src url('/font/averta/averta-lightitalic.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-lightitalic.woff2') format('woff2'),url('/font/averta/averta-lightitalic.woff') format('woff'),url('/font/averta/averta-lightitalic.ttf') format('truetype')

@font-face
  font-family 'Averta'
  src url('/font/averta/averta-regular.eot')
  src url('/font/averta/averta-regular.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-regular.woff2') format('woff2'),url('/font/averta/averta-regular.woff') format('woff'),url('/font/averta/averta-regular.ttf') format('truetype')

@font-face
  font-family 'Averta-RegularItalic'
  src url('/font/averta/averta-regularitalic.eot')
  src url('/font/averta/averta-regularitalic.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-regularitalic.woff2') format('woff2'),url('/font/averta/averta-regularitalic.woff') format('woff'),url('/font/averta/averta-regularitalic.ttf') format('truetype')

@font-face
  font-family 'Averta-Semibold'
  src url('/font/averta/averta-semibold.eot')
  src url('/font/averta/averta-semibold.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-semibold.woff2') format('woff2'),url('/font/averta/averta-semibold.woff') format('woff'),url('/font/averta/averta-semibold.ttf') format('truetype')

@font-face
  font-family 'Averta-SemiboldItalic'
  src url('/font/averta/averta-semibolditalic.eot')
  src url('/font/averta/averta-semibolditalic.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-semibolditalic.woff2') format('woff2'),url('/font/averta/averta-semibolditalic.woff') format('woff'),url('/font/averta/averta-semibolditalic.ttf') format('truetype')

@font-face
  font-family 'Averta-Bold'
  src url('/font/averta/averta-bold.eot')
  src url('/font/averta/averta-bold.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-bold.woff2') format('woff2'),url('/font/averta/averta-bold.woff') format('woff'),url('/font/averta/averta-bold.ttf') format('truetype')

@font-face
  font-family 'Averta-BoldItalic'
  src url('/font/averta/averta-bolditalic.eot')
  src url('/font/averta/averta-bolditalic.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-bolditalic.woff2') format('woff2'),url('/font/averta/averta-bolditalic.woff') format('woff'),url('/font/averta/averta-bolditalic.ttf') format('truetype')

@font-face
  font-family 'Averta-ExtraBold'
  src url('/font/averta/averta-extrabold.eot')
  src url('/font/averta/averta-extrabold.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-extrabold.woff2') format('woff2'),url('/font/averta/averta-extrabold.woff') format('woff'),url('/font/averta/averta-extrabold.ttf') format('truetype')

@font-face
  font-family 'Averta-ExtraBoldItalic'
  src url('/font/averta/averta-extrabolditalic.eot')
  src url('/font/averta/averta-extrabolditalic.eot?#iefix') format('embedded-opentype'),url('/font/averta/averta-extrabolditalic.woff2') format('woff2'),url('/font/averta/averta-extrabolditalic.woff') format('woff'),url('/font/averta/averta-extrabolditalic.ttf') format('truetype')

placeholder()
	&::-webkit-input-placeholder
		{block}
	&:-moz-placeholder
		{block}
	&::-moz-placeholder
		{block}
	&:-ms-input-placeholder
		{block}
