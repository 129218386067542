.o-auth
  align-items center
  display flex
  flex-flow column nowrap
  height 40%
  justify-content center

  .form
    align-items center
    display flex
    flex-flow column nowrap
    justify-content center

    .button
      height 44px
      justify-content center
      margin-top 16px
      width 100%

  .input
    margin-top 16px

  .button
    align-items center
    display flex
    padding 0 32px
    height 56px
    text-transform uppercase

  .icon
    margin-right 8px

  .link
    color color-white
    font-size 16px
    text-transform none
    margin-top 16px
