@-moz-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-webkit-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-o-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-moz-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
.button {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 5px;
  color: #53284f;
  cursor: pointer;
  display: flex-inline;
  font-size: 15px;
  font-weight: 600;
  height: 44px;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  padding: 0 12px;
  text-transform: uppercase;
  text-decoration: none;
}
.button:hover {
  background-color: rgba(124,163,220,0.2);
}
.button:focus {
  background-color: rgba(124,163,220,0.4);
}
.button.disabled {
  background-color: #fff;
  border: 1px solid #999;
  color: #999;
}
.primary {
  background-color: #53284f;
  border-color: #53284f;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.14);
  color: #fff;
}
.primary:hover {
  background-color: #713d8e;
}
.primary:focus {
  background-color: #7c5095;
}
.primary.disabled {
  background-color: #ebebeb;
  border: none;
  box-shadow: none;
  color: #999;
}
.text {
  color: #53284f;
  background-color: transparent;
  border: 1px solid transparent;
}
.text:hover {
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: transparent;
}
.text:focus {
  background-color: #ebebeb;
}
.text.disabled {
  background-color: #ebebeb;
  border: 1px solid transparent;
}
.alert {
  background-color: #d10023;
  border-color: #d10023;
  color: #fff;
}
.alert:hover {
  background-color: #de0227;
}
.alert:focus {
  background-color: #de0227;
}
.alert.disabled {
  background-color: #b37b84;
  color: #999;
}
/*# sourceMappingURL=src/client/UI/Button/button.module.css.map */