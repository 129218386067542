.login
  align-items center
  background-image url('./login_screen_login.png')
  background-repeat repeat
  display flex
  flex-direction column
  font-size unquote('calc(10px + 2vmin)')
  height 100vh
  justify-content flex-start

.loading
  align-items center
  backdrop-filter unquote('blur(6px)')
  background-color rgba(0, 0, 0, 0.5)
  display flex
  height 100vh
  justify-content center
  position absolute
  width 100vw

.logo-wrap
  align-items center
  display flex
  height 27%
  justify-content center
  width 100%

.logo
  height 100%
  max-height 100px
  margin 0 20px
  max-width 400px
  min-width 265px
  position relative
  width 100%

.error-msg
  min-height 32px
  width 100%

.error
  color color-danube-blue
  font-family Averta
  font-size 20px
  margin 0
  padding-top 12px
  text-align center

