@-moz-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-webkit-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-o-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-moz-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
.pip-wrap {
  -webkit-align-content: space-around;
  -ms-align-content: space-around;
  align-content: space-around;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 24px;
  width: 100%;
}
.pip-wrap .pip {
  border: solid 1.5px #fff;
  border-radius: 100%;
  height: 20px;
  margin-left: 16px;
  width: 20px;
}
.pip-wrap .pip.lit {
  background-color: #808080;
}
.enter-passcode {
  color: #fff;
  font-family: Averta;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.6px;
  line-height: normal;
  text-align: center;
  width: 100%;
}
.keypad {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  margin: 32px 0;
  padding: 0 10px;
  max-width: 400px;
  min-width: 200px;
  width: 100%;
}
.keypad .button-wrapper {
  -webkit-flex-basis: 26%;
  -ms-flex-basis: 26%;
  flex-basis: 26%;
  max-height: 100px;
  max-width: 100px;
  overflow: hidden;
  position: relative;
}
.keypad .button-wrapper:before {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.keypad .circle {
  background-color: #fff;
  border: transparent;
  border-radius: 100%;
  border-width: 0;
  color: #53284f;
  display: inline-block;
  font-family: Averta;
  font-size: 50px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 5%;
  left: 5%;
  touch-action: manipulation;
  width: 90%;
  height: 90%;
}
.keypad .edit-button {
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
}
/*# sourceMappingURL=src/client/Login/Num-Pad/num-pad.module.css.map */