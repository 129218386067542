.pip-wrap
  align-content space-around
  display flex
  flex-direction row
  justify-content center
  margin-bottom 32px
  margin-top 24px
  width 100%

  .pip
    border solid 1.5px color-white
    border-radius 100%
    height 20px
    margin-left 16px
    width 20px

    &.lit
      background-color grey

.enter-passcode
  color color-white
  font-family Averta
  font-size 18px
  font-stretch normal
  font-style normal
  font-weight normal
  letter-spacing 0.6px
  line-height normal
  text-align center
  width 100%

.keypad
  display flex
  flex-flow row wrap
  justify-content center
  margin 32px 0
  padding 0 10px
  max-width 400px
  min-width 200px
  width 100%

  .button-wrapper
    flex-basis 26%
    max-height 100px
    max-width 100px
    overflow hidden
    position relative

    // makes wrapper square
    // wrapper must be square in order for button radius to be perfect circle
    &:before
      content ''
      display block
      padding-bottom 100%

  .circle
    background-color color-white
    border transparent
    border-radius 100%
    border-width 0
    color color-mr-purple
    display inline-block
    font-family Averta
    font-size 50px
    font-stretch normal
    font-style normal
    font-weight normal
    line-height normal
    margin 0
    padding 0
    position absolute
    text-align center
    top 5%
    left 5%
    touch-action manipulation
    size 90%

  .edit-button
    background-color transparent
    color color-white
    font-size 24px
    text-transform uppercase
