@-moz-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-webkit-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-o-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-moz-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-webkit-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-o-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-moz-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
.spinner-wrap {
  width: 78px;
  height: 78px;
  background: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  border-radius: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.spinner {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 8px;
  color: #53284f;
  position: relative;
  text-indent: -99999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  border-radius: 50%;
  position: absolute;
  content: '';
}
.spinner:before {
  width: 37px;
  height: 69px;
  top: 0;
  left: -5px;
  background: #fff;
  border-radius: 69px 0 0 69px;
  -webkit-transform-origin: 37px 34.5px;
  -ms-transform-origin: 37px 34.5px;
  transform-origin: 37px 34.5px;
  -webkit-animation: rotateThatBiz 1.5s infinite ease 1.125s;
  animation: rotateThatBiz 1.5s infinite ease 1.125s;
}
.spinner:after {
  width: 37px;
  height: 69px;
  top: -1px;
  left: 32px;
  background: #fff;
  border-radius: 0 69px 69px 0;
  -webkit-transform-origin: 0 34.5px;
  -ms-transform-origin: 0 34.5px;
  transform-origin: 0 34.5px;
  -webkit-animation: rotateThatBiz 1.5s infinite ease;
  animation: rotateThatBiz 1.5s infinite ease;
}
.loading {
  text-align: center;
  width: 100%;
}
.loading > div {
  -webkit-animation: bounce 1.4s infinite ease-in-out both;
  animation: bounce 1.4s infinite ease-in-out both;
  background-color: #53284f;
  border-radius: 100%;
  display: inline-block;
  height: 16px;
  margin: 0 8px;
  width: 16px;
}
.loading .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loading .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
/*# sourceMappingURL=src/client/UI/Spinner/spinner.module.css.map */