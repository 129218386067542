@import "../../animations.styl"

$buffer = 5px
$aniSpeed = 1.5s

.spinner-wrap
  width 78px
  height @width
  background color-white
  align-items center
  border-radius 16px
  display flex
  justify-content center

.spinner
  width 64px
  height @width

  // box-shadow spread radius defines the width of the spinner
  border-radius 50%
  box-shadow inset 0 0 0 8px
  color color-mr-purple
  position relative
  text-indent -99999em
  transform translateZ(0)

  &:before
  &:after
    border-radius @border-radius
    position absolute
    content ''

  // before/after are semi-circles that rotate around (ofset from eacher other)
  // these will block the box shadow giving the persception of the loading growing
  // and shrinking
  &:before
    width (@width / 2 + $buffer)
    height @height + $buffer
    top 0
    left (0 - $buffer)
    background color-white
    border-radius @height 0 0 @height
    transform-origin @width (@height / 2)
    animation rotateThatBiz $aniSpeed infinite ease ($aniSpeed * 3 / 4)

  &:after
    width (@width / 2 + $buffer)
    height @height + $buffer
    top -1px
    left (@width - $buffer)
    background color-white
    border-radius 0 @height @height 0
    transform-origin 0 (@height / 2)
    animation rotateThatBiz $aniSpeed infinite ease

.loading
  text-align center
  width 100%

  > div
    animation bounce 1.4s infinite ease-in-out both
    background-color color-mr-purple
    border-radius 100%
    display inline-block
    height 16px
    margin 0 8px
    width @height

  .bounce1
    animation-delay -0.32s

  .bounce2
    animation-delay -0.16s
