.button
  align-items center
  background-color white
  border 1px solid color-dusty-gray
  border-radius 5px
  color color-mr-purple
  cursor pointer
  display flex-inline
  font-size 15px
  font-weight 600
  height 44px
  justify-content center
  padding 0 12px
  text-transform uppercase
  text-decoration none

  &:hover
    background-color rgba(124, 163, 220, 0.2)
  &:focus
    background-color rgba(124, 163, 220, 0.4)

  &.disabled
    background-color color-white
    border 1px solid color-dusty-gray
    color color-dusty-gray


.primary
  background-color color-mr-purple
  border-color color-mr-purple
  box-shadow 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)
  color color-white
  &:hover
    background-color #713d8e
  &:focus
    background-color #7c5095
  &.disabled
    background-color color-gallery
    border none
    box-shadow none
    color color-dusty-gray

.text
  color color-mr-purple
  background-color transparent
  border 1px solid transparent
  &:hover
    border-radius 4px
    border 1px solid color-silver
    background-color transparent
  &:focus
    background-color color-gallery
  &.disabled
    background-color color-gallery
    border 1px solid transparent

.alert
  background-color #d10023
  border-color #d10023
  color color-white
  &:hover
    background-color #de0227
  &:focus
    background-color #de0227
  &.disabled
    background-color #b37b84
    color color-dusty-gray
