$border-radius = 4px
.group
  position relative
  display flex
  flex-direction row
  align-items stretch
  height 44px

  .label
    background-color color-dusty-gray
    font-size 18px
    font-weight normal
    pointer-events none
    border-top-left-radius $border-radius
    border-bottom-left-radius $border-radius
    color color-mercury
    text-align center
    display flex
    white-space nowrap
    border $border-radius solid color-dusty-gray
    align-items center
    justify-content center
    width 30px

  .input
    border-top-right-radius $border-radius
    border-bottom-right-radius $border-radius
    border-top-left-radius 0
    border-bottom-left-radius 0
    border 1px solid color-dusty-gray
    display block
    font-size 18px
    padding 10px 10px 10px 5px
    width 100%
    -webkit-appearance none
    -moz-appearance none

    &:focus
      outline none

  .error
    padding 5px 0

  .input:focus ~ .label
  .has-value~ .label
    top -20px
    font-size 14px
    color color-danube-blue

  .input:invalid
    color color-red

  .disabled
      cursor not-allowed
      background-color color-mercury

  input.disable-spinbox
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button
      -webkit-appearance none
      margin 0

    &[type=number]
      -moz-appearance textfield