.icon
  display inline-block
  width 24px
  height @width

  &.use-current-color
    circle
    path
    g
      fill currentColor

    :global(.empty)
      fill none


.icon-white
  circle
  path
    fill #fff

.icon-silver
  circle
  path
    fill color-silver

.icon-dove-gray
  circle
  path
    fill color-dove-gray

.icon-dusty-gray
  circle
  path
    fill color-dusty-gray

.icon-danube-blue
  circle
  path
    fill color-danube-blue

.bg-purple
  circle
  // fill in the background of the circle checkmark but not the path
  // todo: do this only for circle
  path:global(#background)
    fill color-mr-purple
    fill-opacity 1

.bg-white
  circle
  // fill in the background of the circle checkmark but not the path
  // todo: do this only for circle
  path:global(#background)
    fill color-white
    fill-opacity 1

.large
  width 40px
  height @width

.small
  width 18px
  height @width
