@-moz-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-webkit-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-o-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-moz-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
}
.icon.use-current-color circle,
.icon.use-current-color path,
.icon.use-current-color g {
  fill: currentColor;
}
.icon.use-current-color :global(.empty) {
  fill: none;
}
.icon-white circle,
.icon-white path {
  fill: #fff;
}
.icon-silver circle,
.icon-silver path {
  fill: #ccc;
}
.icon-dove-gray circle,
.icon-dove-gray path {
  fill: #666;
}
.icon-dusty-gray circle,
.icon-dusty-gray path {
  fill: #999;
}
.icon-danube-blue circle,
.icon-danube-blue path {
  fill: #7ca3dc;
}
.bg-purple path:global(#background) {
  fill: #53284f;
  fill-opacity: 1;
}
.bg-white path:global(#background) {
  fill: #fff;
  fill-opacity: 1;
}
.large {
  width: 40px;
  height: 40px;
}
.small {
  width: 18px;
  height: 18px;
}
/*# sourceMappingURL=src/client/UI/Icon/icon.module.css.map */