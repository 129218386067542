@-moz-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-webkit-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-o-keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@keyframes blinker {
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
@-moz-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotateThatBiz {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
.group {
  margin-bottom: 22px;
  margin-top: 25px;
  position: relative;
}
.group .input {
  border: none;
  border-bottom: 1px solid #999;
  display: block;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  width: 100%;
}
.group .input:focus {
  outline: none;
}
.group .error {
  padding: 5px 0;
}
.group .label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  left: 5px;
  pointer-events: none;
  position: absolute;
  top: 10px;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
.group .input:focus ~ .label,
.group .has-value~ .label {
  top: -20px;
  font-size: 14px;
  color: #7ca3dc;
}
.group .input:invalid {
  color: #f00;
}
.group .disabled {
  cursor: not-allowed;
  background-color: #e3e3e3;
}
.group .bar {
  display: block;
  position: relative;
  width: 100%;
}
.group .bar:before,
.group .bar:after {
  background: #7ca3dc;
  bottom: 1px;
  content: '';
  height: 2px;
  position: absolute;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  width: 0;
}
.group .bar:before {
  left: 50%;
}
.group .bar:after {
  right: 50%;
}
.group .input:focus ~ .bar:before,
.group .input:focus ~ .bar:after {
  width: 50%;
}
.group .highlight {
  height: 60%;
  left: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 25%;
  width: 100px;
}
.group .input:focus ~ .highlight {
  -webkit-animation: heighlighter 0.3s ease;
  animation: heighlighter 0.3s ease;
}
.box {
  margin: 0;
  height: 44px;
}
.box .input {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  padding: 12px 10px 0 12px;
  height: 44px;
}
.box .input:focus ~ .label,
.box .has-value~ .label {
  top: 3px;
  font-size: 10px;
  color: #7ca3dc;
}
.box .input:invalid,
.box .invalid {
  border: 1px solid #f00;
}
.box .label {
  text-transform: none;
  left: 12px;
}
@-moz-keyframes heighlighter {
  from {
    background: #7ca3dc;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes heighlighter {
  from {
    background: #7ca3dc;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-o-keyframes heighlighter {
  from {
    background: #7ca3dc;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes heighlighter {
  from {
    background: #7ca3dc;
  }
  to {
    width: 0;
    background: transparent;
  }
}
/*# sourceMappingURL=src/client/UI/Input/input.module.css.map */