.group
  margin-bottom 22px
  margin-top 25px
  position relative

  .input
    border none
    border-bottom 1px solid color-dusty-gray
    display block
    font-size 18px
    padding 10px 10px 10px 5px
    width 100%

    &:focus
      outline none

  .error
    padding 5px 0

  .label
    color color-dusty-gray
    font-size 18px
    font-weight normal
    left 5px
    pointer-events none
    position absolute
    top 10px
    transition 0.2s ease all

  .input:focus ~ .label
  .has-value~ .label
    top -20px
    font-size 14px
    color color-danube-blue

  .input:invalid
    color color-red

  .disabled
      cursor not-allowed
      background-color color-mercury

  .bar
    display block
    position relative
    width 100%

  .bar:before,
  .bar:after
    background color-danube-blue
    bottom 1px
    content ''
    height 2px
    position absolute
    transition 0.2s ease all
    width 0

  .bar:before
    left 50%

  .bar:after
    right 50%

  .input:focus ~ .bar:before,
  .input:focus ~ .bar:after
    width 50%

  .highlight
    height 60%
    left 0
    opacity 0.5
    pointer-events none
    position absolute
    top 25%
    width 100px


  .input:focus ~ .highlight
    animation heighlighter 0.3s ease


.box
  margin 0
  height 44px

  .input
    border 1px solid color-mercury
    border-radius 4px
    font-size 16px
    font-stretch normal
    font-style normal
    font-weight normal
    line-height 1.5
    padding 12px 10px 0 12px
    height @height

  .input:focus ~ .label
  .has-value~ .label
    top 3px
    font-size 10px
    color color-danube-blue

  .input:invalid
  .invalid
    border 1px solid color-red

  .label
    text-transform none
    left 12px


@keyframes heighlighter
  from
    background color-danube-blue
  to
    width 0
    background transparent